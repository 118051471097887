module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyRemarkPlugins":[{"resolve":"/codebuild/output/src1342474830/src/nf-developer-portal-website/node_modules/gatsby-remark-autolink-headers","id":"95160090-7e5d-5ef8-849c-77a8e928ac56","name":"gatsby-remark-autolink-headers","version":"5.5.0","modulePath":"/codebuild/output/src1342474830/src/nf-developer-portal-website/node_modules/gatsby-remark-autolink-headers/index.js","pluginOptions":{"plugins":[],"elements":["h1","h2","h3","h4","h5","h6"]},"nodeAPIs":["pluginOptionsSchema"],"browserAPIs":["onInitialClientRender","shouldUpdateScroll"],"ssrAPIs":["onRenderBody"]},{"resolve":"/codebuild/output/src1342474830/src/nf-developer-portal-website/node_modules/gatsby-remark-images","id":"580fd6f0-a0f4-5cb7-ac28-2bea65acb525","name":"gatsby-remark-images","version":"6.5.2","modulePath":"/codebuild/output/src1342474830/src/nf-developer-portal-website/node_modules/gatsby-remark-images/index.js","pluginOptions":{"plugins":[]},"nodeAPIs":["pluginOptionsSchema"],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]}],"extensions":[".mdx"],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/codebuild/output/src1342474830/src/nf-developer-portal-website","commonmark":false},
    },{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"elements":["h1","h2","h3","h4","h5","h6"]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","display":"minimal-ui","icon":"src/images/favicon.ico","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"8f7d76166c1495a72f07dae0f90e0592"},
    },{
      plugin: require('../node_modules/gatsby-plugin-apollo/gatsby-browser.js'),
      options: {"plugins":[],"uri":"https://gw.staging.llc.nuflights.com/core/graphql"},
    },{
      plugin: require('../node_modules/gatsby-plugin-breadcrumb/gatsby-browser.js'),
      options: {"plugins":[],"useAutoGen":true,"autoGenHomeLabel":"Home","exclude":["**/dev-404-page/**","**/404/**","**/404.html","**/offline-plugin-app-shell-fallback/**"],"excludeOptions":{"separator":"."},"trailingSlashes":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
